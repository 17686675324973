import React from "react";
import SEO from "../components/seo";
import Chapter4 from "src/views/Chapter4";

const IndexPage = () => {
  return (
    <>
      <SEO title="Poezja, proza, rysunki" description="Wybitne wiersze, pełna absurdalnego humoru proza, grafiki i rysunki – o tym mówią  badacze i znawców literatury Krzysztofa Kamila Baczyńskiego. " image={require('images/seo/4.jpg')}/>
      <Chapter4 />
    </>
  );
};

export default IndexPage;
