import React from "react"
import { Icon, WrapperLink, Text, Title, FlexWrapper } from "./styles"
import nextIcon from "images/next_icon.png"

const NextChapter = ({ title, href }) => {
  return (
    <WrapperLink to={href}>
      <FlexWrapper>
        <Text>Następny rozdział</Text>
        <Title>{title}</Title>
      </FlexWrapper>
      <Icon src={nextIcon} />
    </WrapperLink>
  )
}

export default NextChapter
