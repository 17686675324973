import React from "react"
import AudioPlayer, { AudioPlayerGroup } from "src/components/AudioPlayer"
import TitlePage from "src/components/TitlePage"
import CustomGrid, { Section, Group } from "src/components/CustomGrid"
import { GRIDS } from "src/util"
import { useArticles } from "src/hooks"
import parse from "html-react-parser"
import { BodyTextWrap, TitleContrast } from "src/atoms/typography"
import { Bookshelf } from "src/atoms"
import NextChapter from "../../components/NextChapter"
import { AnimatePresence, motion } from "framer-motion"

const Chapter4 = () => {
  const articles = useArticles({ articleCategory: 9049 })
  const { list } = articles
  if (list.length)
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3.5 }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <TitlePage
              title={list[0]?.Title}
              subtitle={list[0].Lead}
              description={parse(list[0].Content)}
            />
            <Section>
              <CustomGrid>
                <Group marginB="4rem">
                  <TitleContrast>{list[1].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[1].Content)}
                  </BodyTextWrap>
                  <AudioPlayerGroup list={list.slice(4, 6)} />
                </Group>
                <CustomGrid
                  photos={[list[2], list[3], list[7], list[8]]}
                  grid={GRIDS.p11}
                  heightRatio={1.2}
                />
                <CustomGrid
                  photos={list.slice(9, 13)}
                  grid={GRIDS.p11}
                  heightRatio={1.35}
                />
                <div>
                  <AudioPlayerGroup list={list.slice(13, 16)} />
                </div>
              </CustomGrid>
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[16].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[16].Content)}
                  </BodyTextWrap>
                </div>
                <CustomGrid
                  photos={[list[17], list[18]]}
                  grid={GRIDS.p1}
                  heightRatio={0.68}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid>
                  <AudioPlayerGroup list={list.slice(19, 27)} />
                </CustomGrid>
              </Group>
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[27]?.Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[27].Content)}
                  </BodyTextWrap>
                  <AudioPlayerGroup list={[list[31]]} />
                </div>
                <CustomGrid
                  photos={[list[28], list[29], list[30]]}
                  grid={GRIDS.p14}
                  heightRatio={0.68}
                />
              </CustomGrid>
            </Section>
            <Section>
              <TitleContrast>{list[32].Title}</TitleContrast>
              <CustomGrid>
                <BodyTextWrap style={{ marginBottom: "3rem" }}>
                  {parse(list[32].Content)}
                </BodyTextWrap>
                <BodyTextWrap style={{ marginBottom: "3rem" }}>
                  {parse(list[33].Content)}
                </BodyTextWrap>
                <CustomGrid
                  photos={[list[34], list[35], list[38], list[39]]}
                  grid={GRIDS.p11}
                  heightRatio={1.1}
                />

                <div>
                  <AudioPlayerGroup list={[list[36], list[37], list[40]]} />
                </div>
              </CustomGrid>
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[41]?.Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[41].Content)}
                  </BodyTextWrap>
                  <AudioPlayerGroup list={[list[44]]} />
                </div>
                <CustomGrid
                  photos={[list[42], list[43]]}
                  grid={GRIDS.p11}
                  heightRatio={0.63}
                />
              </CustomGrid>
              <CustomGrid
                photos={list.slice(45, 54)}
                grid={GRIDS.p4}
                heightRatio={0.77}
              />
            </Section>
            <Section>
              <Bookshelf />
              <CustomGrid grid={GRIDS.p4}>
                <AudioPlayerGroup list={[list[54], list[56], list[58]]} />
              </CustomGrid>
              <Group marginB="4rem">
                <CustomGrid
                  photos={[list[55], list[57], list[59]]}
                  grid={GRIDS.p4}
                  heightRatio={0.37}
                />
              </Group>
              <CustomGrid
                photos={list.slice(60, 66)}
                grid={GRIDS.p4}
                heightRatio={0.52}
              />
            </Section>
          </div>
          <NextChapter title="Wojna" href="/wojna" />
        </motion.div>
      </AnimatePresence>
    )
  return null
}

export default Chapter4
