import styled from "styled-components"
import { Link } from "gatsby"

export const WrapperLink = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: max-content;
  text-decoration: none;
  font-weight: 500;
  @media (max-width: 768px) {
    padding-bottom: 8rem;
  }
`

export const Text = styled.p`
  color: #2e2e2e;
  font-size: 1.25rem;
  padding-right: 1.1rem;
  margin-top: 0.5rem;
  @media (max-width: 575px) {
    margin: 0;
    padding: 0;
  }
`

export const Title = styled.p`
  color: #2e2e2e;
  font-size: 1.875rem;
  text-transform: uppercase;
`

export const Icon = styled.img`
  transform: translateX(-0.4rem);
  height: 4.31rem;
  @media (max-width: 575px) {
    height: 4rem;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-end;
    margin-right: 1.5rem;
  }
`
